export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const ModelsToggle = () => import('../../components/models/toggle.vue' /* webpackChunkName: "components/models-toggle" */).then(c => wrapFunctional(c.default || c))
export const FaqsRow = () => import('../../components/faqs/row.vue' /* webpackChunkName: "components/faqs-row" */).then(c => wrapFunctional(c.default || c))
export const ServicesRow = () => import('../../components/services/row.vue' /* webpackChunkName: "components/services-row" */).then(c => wrapFunctional(c.default || c))
export const UiNotification = () => import('../../components/ui/Notification.vue' /* webpackChunkName: "components/ui-notification" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiSlider = () => import('../../components/ui/Slider.vue' /* webpackChunkName: "components/ui-slider" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/Tabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
