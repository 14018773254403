//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    isLoggedIn() {
      return this.$auth?.loggedIn || false;
    }
  }
};
