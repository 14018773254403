//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getBrands from '~/queries/brands';
import getContactInfo from '~/queries/contactInfo';

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  async fetch() {
    const locale = this.$i18n.locale.slice(0, 3) + this.$i18n.locale.slice(3).toUpperCase();

    const client = this.$apolloProvider.defaultClient;
    const contactResponse = await client.query({
      query: getContactInfo,
      variables: { locale: locale }
    });

    this.contactInfo = contactResponse.data.informationsDeContact.data.attributes;

    if (this.isLoggedIn) {
      const brandResponse = await client.query({
        query: getBrands,
        variables: { locale: locale },
        context: {
          headers: {
            Authorization: this.$auth.getToken('local')
          }
        }
      });

      const brands = brandResponse.data.marques.data;

      this.brands = brands;
    }
  },
  data() {
    return {
      brands: null,
      contactInfo: null
    };
  },
  methods: {
    getSocialUrl(name) {
      return this.contactInfo?.socialUrls?.find(x => x.site === name)?.url;
    }
  },
  computed: {},
  watch: {
    isLoggedIn(value, old) {
      if (!old && value) {
        this.$fetch();
      }
    }
  }
};
