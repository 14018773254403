//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getContactInfo from '~/queries/contactInfo';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  async fetch() {
    const client = this.$apolloProvider.defaultClient;
    const locale = this.$i18n.locale.slice(0, 3) + this.$i18n.locale.slice(3).toUpperCase();
    const response = await client.query({
      query: getContactInfo,
      variables: { locale: locale }
    });

    const contactInfo = response.data.informationsDeContact.data.attributes;
    this.contactInfo = contactInfo;
  },
  async created() {},
  data() {
    return {
      contactInfo: null
    };
  },
  methods: {
    ...mapActions(['setMeetingPopinVisibility']),
    async logoutUser() {
      await this.$auth.logout();
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales
        .filter(x => x.code !== this.$i18n.locale)
        .map(x => ({ ...x, displayName: x.code.charAt(0).toUpperCase() + x.code.slice(1, 2) }));
    }
  }
};
