//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getBrands from '~/queries/brands';
import getModels from '~/queries/models';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  async fetch() {
    const locale = this.$i18n.locale.slice(0, 3) + this.$i18n.locale.slice(3).toUpperCase();
    const client = this.$apolloProvider.defaultClient;
    const response = await client.query({
      query: getBrands,
      variables: { locale: locale },
      context: {
        headers: {
          Authorization: this.$auth.getToken('local')
        }
      }
    });

    const brands = response.data.marques.data;
    this.brands = brands;
  },
  data() {
    return {
      brands: [],
      isSearching: false,
      openedBrandIds: [],
      searchOptions: []
    };
  },
  methods: {
    onSelect(item) {
      this.$router.push(item.path);
    },
    async search(keyword) {
      this.isSearching = true;
      const locale = this.$i18n.locale.slice(0, 3) + this.$i18n.locale.slice(3).toUpperCase();
      const client = this.$apolloProvider.defaultClient;

      const response = await client.query({
        query: getModels,
        variables: {
          limit: 10,
          locale: locale,
          where: { name_contains: keyword }
        },
        context: {
          headers: {
            Authorization: this.$auth.getToken('local')
          }
        }
      });

      const models = response.data.modeles.data;

      this.searchOptions = models.map(x => ({
        path: this.localePath({ name: 'models-slug', params: { slug: x.attributes.slug } }),
        text: `${x.attributes.brand?.data?.attributes?.name} - ${x.attributes.name}`,
        value: x.id
      }));
      this.isSearching = false;
    },
    toggleBrand(id) {
      const index = this.openedBrandIds.findIndex(x => x === id);

      if (index > -1) {
        this.openedBrandIds.splice(index, 1);
      } else {
        this.openedBrandIds.push(id);
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.$fetch();
    }
  }
};
