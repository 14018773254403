import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2ab38a45 = () => interopDefault(import('../pages/technical-bulletins/index.vue' /* webpackChunkName: "pages/technical-bulletins/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _05264fca = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _37296ece = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _8cbd094e = () => interopDefault(import('../pages/legal-notice/index.vue' /* webpackChunkName: "pages/legal-notice/index" */))
const _23ae4687 = () => interopDefault(import('../pages/shipping-and-return-policy/index.vue' /* webpackChunkName: "pages/shipping-and-return-policy/index" */))
const _0d6930f4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _e9039c6a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _10b800f1 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _34f5980f = () => interopDefault(import('../pages/models/_slug.vue' /* webpackChunkName: "pages/models/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accueil",
    component: _2dfb1658,
    name: "index___fr-ca"
  }, {
    path: "/bulletins-techniques",
    component: _2ab38a45,
    name: "technical-bulletins___fr-ca"
  }, {
    path: "/connexion",
    component: _cad79e9c,
    name: "login___fr-ca"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact___fr-ca"
  }, {
    path: "/faqs",
    component: _05264fca,
    name: "faqs___fr-ca"
  }, {
    path: "/forgot-password",
    component: _37296ece,
    name: "forgot-password___fr-ca"
  }, {
    path: "/marques",
    component: _126ea239,
    name: "brands___fr-ca"
  }, {
    path: "/mentions-legales",
    component: _8cbd094e,
    name: "legal-notice___fr-ca"
  }, {
    path: "/politique-de-livraison-et-de-retour",
    component: _23ae4687,
    name: "shipping-and-return-policy___fr-ca"
  }, {
    path: "/reset-password",
    component: _0d6930f4,
    name: "reset-password___fr-ca"
  }, {
    path: "/services",
    component: _e9039c6a,
    name: "services___fr-ca"
  }, {
    path: "/en-ca/brands",
    component: _126ea239,
    name: "brands___en-ca"
  }, {
    path: "/en-ca/contact",
    component: _1d238929,
    name: "contact___en-ca"
  }, {
    path: "/en-ca/faqs",
    component: _05264fca,
    name: "faqs___en-ca"
  }, {
    path: "/en-ca/forgot-password",
    component: _37296ece,
    name: "forgot-password___en-ca"
  }, {
    path: "/en-ca/home",
    component: _2dfb1658,
    name: "index___en-ca"
  }, {
    path: "/en-ca/legal-notice",
    component: _8cbd094e,
    name: "legal-notice___en-ca"
  }, {
    path: "/en-ca/login",
    component: _cad79e9c,
    name: "login___en-ca"
  }, {
    path: "/en-ca/reset-password",
    component: _0d6930f4,
    name: "reset-password___en-ca"
  }, {
    path: "/en-ca/services",
    component: _e9039c6a,
    name: "services___en-ca"
  }, {
    path: "/en-ca/shipping-and-return-policy",
    component: _23ae4687,
    name: "shipping-and-return-policy___en-ca"
  }, {
    path: "/en-ca/technical-bulletins",
    component: _2ab38a45,
    name: "technical-bulletins___en-ca"
  }, {
    path: "/en-ca/brands/:slug",
    component: _10b800f1,
    name: "brands-slug___en-ca"
  }, {
    path: "/en-ca/models/:slug",
    component: _34f5980f,
    name: "models-slug___en-ca"
  }, {
    path: "/marques/:slug",
    component: _10b800f1,
    name: "brands-slug___fr-ca"
  }, {
    path: "/modeles/:slug",
    component: _34f5980f,
    name: "models-slug___fr-ca"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
